import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import Input from "../../components/Forms/Input/Input";
import Button from "../../components/Button";
import SecondaryButton from "../../components/SecondaryButton";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {connect} from "../../state/useGlobal";
import Config from "../../config";
import {Formik} from "formik";
import * as Yup from "yup";
import api from "../../services/api";
import * as Sentry from "@sentry/react";
import history from "../../routes/history";
import toast from "react-hot-toast";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import CountrySwitcher from "../../components/CountrySwitcher";
import {Helmet} from "react-helmet";
import CartSteps from "../../components/CartSteps";
import {STORE as M2_STOTE, STORE_LANGUAGES as M2_STORE_LANGUAGES} from "../../constants/storeCode";
import mixpanel from "mixpanel-browser";

class ActiveScreen extends Component {
  constructor(props) {
    super(props);

    const { globalState } = this.props;
    const { groupOrder, store } = globalState;
    const { status } = groupOrder;

    this.state = {
      isLoading: false,
      isNotFound: status === STATUS_NOT_FOUND,
      shopURL: Config.stores[store],
    };
  }

  componentDidMount() {
    const { globalActions } = this.props;
    globalActions.initCheckout();
    globalActions.initGroupOrder();
    globalActions.initFakeCart();
  }

  handleOnChange = (e) => {
    this.setState({ shopURL: Config.stores[e] });
  };

  render() {
    const { t, i18n } = this.props;
    const { shopURL, isNotFound, isLoading } = this.state;

    return (
      <AppLayout
        contentBackgroundColor="bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6"
        textColor="text-gunmetal-black"
        border={false}
      >
        <Helmet>
          <title>{t("Group Order")}</title>
        </Helmet>
        <div className="flex flex-col h-full min-h-screen">
          <Header confirm={false} title={t("Group Order")}>
            <CartSteps />
          </Header>

          <Formik
            validateOnMount
            initialValues={{
              schoolCode: "",
            }}
            validationSchema={Yup.object().shape({
              schoolCode: Yup.string().required(t("This is a mandatory field")),
            })}
            onSubmit={async (values) => {
              const { globalState, globalActions, ga4 } = this.props;
              let { schoolCode } = values;
              schoolCode = schoolCode.toLowerCase();

              this.setState({ isLoading: true });
              try {
                const groupOrderData = await api.get(
                  `/calcuso-group-order/${schoolCode.trim()}`
                );

                const { data } = groupOrderData;
                const { success, payload } = data;
                  try {
                      ga4.pageview('/calcuso-group-order-input-screen', '/calcuso-group-order-input-screen', 'Group Order | Input school code');
                      mixpanel.track_pageview({
                          "page": "Group Order | Input school code",
                          "school_code": schoolCode,
                      })
                  } catch (e) {
                      console.log(e);
                  }
                if (success || payload) {
                  if (M2_STOTE[payload?.store?.id]) {
                    globalActions.setStore(payload?.store?.id);
                    i18n.changeLanguage(M2_STORE_LANGUAGES[payload?.store?.id]);
                  }

                  history.push(`/group-orders/${schoolCode.trim()}`);

                  this.setState({ isNotFound: false });
                } else {
                  this.setState({ isNotFound: true });
                }
              } catch (error) {
                toast.error(t("Something went wrong"));
                Sentry.setUser(globalState);
                Sentry.captureException(error);
              }

              this.setState({ isLoading: false });
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              handleBlur,
              isValid,
            }) => (
              <form
                className="flex flex-col justify-between flex-1 h-full"
                onSubmit={handleSubmit}
              >
                <div className="flex items-center justify-center flex-1">
                  <div className="w-full px-4 py-24 md:w-2/3 xl:w-1/3 sm:px-6 lg:px-8">
                    <div className="flex flex-col items-start w-full space-y-4">
                      {isNotFound ? (
                        <div className="flex flex-col items-center w-full space-y-6">
                          <h3 className="text-xl font-medium tracking-wide text-center text-fiery-rose-500">
                            {t(
                              "Unfortunately, we do not know the code entered."
                            )}
                          </h3>
                          <h3 className="text-xl font-medium tracking-wide text-center text-gunmetal-black-500">
                            {t("Try again!")}
                          </h3>
                        </div>
                      ) : (
                        ""
                      )}
                      <Input
                        name="schoolCode"
                        placeholder={t("School code")}
                        size="lg"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schoolCode}
                        required
                        error={touched.schoolCode && errors.schoolCode}
                        errorMessage={errors.schoolCode}
                        autoComplete="off"
                        alignCenter={true}
                      />
                      <CountrySwitcher onChange={this.handleOnChange} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="w-full px-4 py-12 md:w-2/3 xl:w-2/5 ">
                    <div className="flex flex-col-reverse items-center justify-between w-full space-y-4 space-y-reverse md:space-y-0 md:space-x-4 md:flex-row">
                      <SecondaryButton
                        type="a"
                        href={shopURL}
                        color="text-celadon-green-700 bg-transparent border-celadon-green-300 hover:border-celadon-green-500 hover:text-celadon-green-900 focus:ring-celadon-green-500"
                        size="xl"
                        full={true}
                      >
                        <ArrowLeftIcon
                          className="w-5 h-5 mr-3 -ml-1 text-celadon-green-700"
                          aria-hidden="true"
                        />
                        <span>{t("Back to the Store")}</span>
                      </SecondaryButton>
                      <Button
                        id="input-school-code-next-button"
                        type="submit"
                        color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                        size="xl"
                        full={true}
                        disabled={!isValid}
                        loading={isLoading}
                      >
                        {t("next")}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </AppLayout>
    );
  }
}

export default connect(withTranslation()(ActiveScreen));
