export const DE = "de";
export const FR = "fr";
export const PT = "pt";
export const EN = "en";
export const NL = "nl";
export const IT = "it";
export const BE_NL = "be_nl";
export const BE_FR = "be_fr";
export const BE_DE = "be_de";

export const STORE = {
    1: DE,
    4: FR,
    2: PT,
    5: EN,
    6: NL,
    3: IT,
    14: BE_NL,
    15: BE_FR,
    16: BE_DE,
};

export const STORE_LANGUAGES = {
    1: DE,
    4: FR,
    2: PT,
    5: EN,
    6: NL,
    3: IT,
    14: NL,
    15: FR,
    16: DE,
};
